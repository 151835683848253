import React, { useState,useEffect } from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  createTheme,
  ThemeProvider,
  LinearProgress,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EB578A',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  imagePreview: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  progressBar: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
}));

const Dodaj = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(false); // State to track upload success
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id')
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('loadData.php?id=' + id, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // Include any necessary parameters he
          })
        });
  
        const data = await response.json();
        setLimit(data)
        console.log(limit)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleImageChange = (e) => {
    console.log(limit)
    setImages([...images, ...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let image_limit = parseInt(limit.user.image_limit)
    // Check if the number of selected images exceeds the limit
    if (images.length > image_limit) {
      
      setError(`Przekroczono ilość zdjęć, które możesz dodać :( ${image_limit} `);
      return; // Exit early if the limit is exceeded
    }
  
    const formData = new FormData();
    formData.append('text', text);
    formData.append('user_id', id);
  
    // Append only the first 'imageLimit' number of images to the form data
    images.slice(0, image_limit).forEach((image) => {
      formData.append('images[]', image);
    });
  
    try {
      const response = await fetch('submit2.php', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        setText('');
        setImages([]);
        setError('');
        setUploadProgress({});
        setUploadSuccess(true); // Set upload success to true
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setError('Wystąpił błąd. Spróbuj ponownie później.');
    }
  };
  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5">
            Dodaj zdjęcia i tekst
          </Typography>
          <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
            Podziel się swoimi wspomnieniami! Dodaj zdjęcia z uroczystości, aby uzupełnić naszą księgę gości.
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
          {!uploadSuccess && (
  <TextField
    variant="outlined"
    margin="normal"
    required
    fullWidth
    id="text"
    label="Wprowadź tekst"
    name="text"
    multiline
    minRows={4}
    value={text}
    onChange={handleTextChange}
  />
)}
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              multiple
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
            {limit && limit.user && limit.user.image_limit && (
  <h2>Możesz dodać {limit.user.image_limit} obrazów!</h2>
)}
<label htmlFor="contained-button-file">
  {!uploadSuccess && (
    <Button
      variant="contained"
      color="primary"
      component="span"
      fullWidth
      className={classes.submit}
    >
      Wybierz zdjęcia
    </Button>
  )}
</label>
<input
  accept="image/*"
  id="contained-button-file"
  type="file"
  capture="environment"
  multiple
  onChange={handleImageChange}
  style={{ display: 'none' }}
/>

{!uploadSuccess && (
  <Button
    variant="contained"
    color="primary"
    fullWidth
    className={classes.submit}
    onClick={() => {
      // Trigger input file element click to open file picker
      const fileInput = document.getElementById('contained-button-file');
      fileInput.click();
    }}
  >
    Zrób zdjęcie
  </Button>
)}
            {images.map((image, index) => (
  <div key={index}>
    <img
      src={URL.createObjectURL(image)}
      alt={`Obraz ${index}`}
      className={classes.imagePreview}
    />

    <Button
      variant="contained"
      color="secondary"
      onClick={() => handleRemoveImage(index)}
    >
      Usuń
    </Button>
  </div>
))}
            <Typography variant="body2" color="error">{error}</Typography>
            {uploadSuccess && ( // Display success message if all uploads are successful
              <Typography variant="body1" color="primary">
                Wszystkie zdjęcia zostały pomyślnie przesłane!
              </Typography>
            )}
{!uploadSuccess && (
  <Button
    type="submit"
    fullWidth
    variant="contained"
    color="primary"
    className={classes.submit}
  >
    Wyślij
  </Button>
)}
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default Dodaj;
