import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardMedia, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, SwipeableDrawer, List, ListItem, ListItemText, AppBar, Toolbar, IconButton, Avatar } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import Cookies from 'js-cookie';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation
  } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
  import JSZip from 'jszip';



  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      paddingTop: '64px', // Adjust for AppBar height
    },
    card: {
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
    },
    media: {
      height: 140,
    },
    dialogContent: {
      textAlign: 'center',
      padding: 0,
    },
    drawer: {
      width: '80vw',
      padding: theme.spacing(2),
    },
    appBar: {
      backgroundColor: '#EB578A',
      marginBottom: 20,
      zIndex: theme.zIndex.drawer + 1, // Ensure AppBar is above the SwipeableDrawer
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
  }));

  const SaveAllImagesButton = ({ wpisy }) => {
    console.log("wpisy:", wpisy);
    const handleSaveAllImages = () => {
        console.log("wpisy inside handleSaveAllImages:", wpisy);
        // Create a new instance of JSZip
        const zip = new JSZip();

        // Counter for creating unique filenames
        let counter = 1;

        // Iterate over each wpis
        wpisy.forEach((wpis) => {
            console.log(wpis);
            // Fetch each image in the wpis
            fetch(wpis.image)
                .then((response) => response.blob())
                .then((blob) => {
                    // Add the image blob to the zip file with a unique name
                    zip.file(`wpis_${wpis.id}_image_${wpis.zdjecie_id}_${counter++}.jpg`, blob);

                    // Check if all images have been fetched and added
                    if (counter === wpisy.length) {
                        // Generate the zip file after all fetch operations are complete
                        zip.generateAsync({ type: 'blob' })
                            .then((content) => {
                                // Create a URL for the blob
                                const url = window.URL.createObjectURL(content);

                                // Create a link element to trigger the download
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = 'all_wpis_images.zip';

                                // Simulate a click on the link to trigger the download
                                link.click();

                                // Clean up by revoking the URL
                                window.URL.revokeObjectURL(url);
                            })
                            .catch((error) => {
                                console.error('Error generating zip file:', error);
                            });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching image:', error);
                });
        });
    };

    return (
      <Button style={{ backgroundColor: "#EB578A" }} variant="contained" color="primary" onClick={() => handleSaveAllImages()}>Zapisz wszystkie zdjęcia</Button>
    );
};

const ProfilePage = ({ isAuthenticated, setIsAuthenticated }) => {
  const classes = useStyles();
  const [wpisy, setWpisy] = useState([])
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id') || 1; // Default to 1 if id parameter is not provided
  const first_name = searchParams.get('first_name');

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const response = await fetch('http://localhost/Ksiega/returnWpis.php?id=' + id);
        const response = await fetch('returnWpis.php?id=' + id);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setWpisy(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

   
  const [selectedWpis, setSelectedWpis] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isImageDialogOpen, setImageDialogOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleImageClick = (wpis) => {
    console.log("Clicked wpis:", wpis);
    setSelectedWpis(wpis);
    setImageDialogOpen(true);
  };

  const groupedWpisy = wpisy.reduce((acc, wpis) => {
    if (!acc[wpis.id]) {
      acc[wpis.id] = {
        ...wpis,
        zdjecia: [],
      };
    }
    acc[wpis.id].zdjecia.push({ id: wpis.zdjecie_id, image: wpis.image });
    return acc;
  }, {});

 

  const handleSaveToPDF = () => {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const promises = [];
  
    // Loop through each wpis and add its capture promise to the promises array
    wpisy.forEach((wpis, index) => {
        const { text, image } = wpis;
        if (image) {
            const promise = new Promise((resolve, reject) => {
                // Create an Image object
                const img = new Image();
                img.crossOrigin = "anonymous"; // Allow CORS for the image
                img.onload = () => {
                    // Create a canvas element
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    // Draw the image onto the canvas
                    ctx.drawImage(img, 0, 0);
                    // Convert the canvas to a base64 data URL
                    const imgData = canvas.toDataURL('image/png');
                    // Add the base64 image data to the PDF
                    pdf.addImage(imgData, 'PNG', 40, 40, 500, 600); // Adjust coordinates and dimensions as needed
                    // Add the text to the PDF
                    pdf.text(text, 40, 20); // Adjust coordinates as needed
                    if (index < wpisy.length - 1) {
                        pdf.addPage();
                    }
                    resolve();
                };
                img.onerror = (error) => {
                    reject(error);
                };
                // Set the image source to the data URL
                img.src = image;
            });
            promises.push(promise);
        }
    });

    // Wait for all promises to resolve before saving the PDF
    Promise.all(promises).then(() => {
        pdf.save('all_wpisy.pdf');
    });
};



  
  const handleSaveImage = (imageURL) => {
    // Create an XMLHttpRequest to fetch the image data
    const xhr = new XMLHttpRequest();
    xhr.open('GET', imageURL, true);
    xhr.responseType = 'blob';
  
    xhr.onload = function () {
      // Create a blob from the image data
      const blob = new Blob([xhr.response], { type: 'image/jpeg' });
  
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg'; // Set the default filename for the downloaded image
  
      // Simulate a click on the link to trigger the download
      link.click();
  
      // Clean up by revoking the URL
      window.URL.revokeObjectURL(url);
    };
  
    // Send the XMLHttpRequest
    xhr.send();
  };

  const handleSaveAllImages = () => {
    if (selectedWpis) {
      // Create a new instance of JSZip
      const zip = new JSZip();
      
      // Array to store promises for each image fetch operation
      const fetchPromises = [];
  
      // Iterate over each image in the wpis
      selectedWpis.zdjecia.forEach((image) => {
        // Fetch each image and add it to the zip file
        const fetchPromise = fetch(image.image)
          .then((response) => response.blob())
          .then((blob) => {
            // Add the image blob to the zip file with a unique name
            zip.file(`image_${image.id}.jpg`, blob);
          })
          .catch((error) => {
            console.error('Error fetching image:', error);
          });
          
        fetchPromises.push(fetchPromise); // Add the promise to the array
      });
  
      // Wait for all fetch operations to complete
      Promise.all(fetchPromises)
        .then(() => {
          // Generate the zip file
          zip.generateAsync({ type: 'blob' })
            .then((content) => {
              // Create a URL for the blob
              const url = window.URL.createObjectURL(content);
  
              // Create a link element to trigger the download
              const link = document.createElement('a');
              link.href = url;
              link.download = 'wpis_images.zip';
  
              // Simulate a click on the link to trigger the download
              link.click();
  
              // Clean up by revoking the URL
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error('Error generating zip file:', error);
            });
        });
    }
  };
  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
  };

  const handleWpisDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleWpisDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    // Remove the isAuthenticated cookie
    Cookies.remove('isAuthenticated');
    // Update the authentication state
    setIsAuthenticated(false);
    <Navigate to="/" />
  };
  return (
    <div className={classes.root}>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Księga Gości
        </Typography>
        {isAuthenticated && (
          <>
            <Avatar className={classes.avatar}>{first_name ? first_name[0] : 'N'}</Avatar>
            <Button color="inherit" onClick={handleLogout}>Wyloguj</Button>
          </>
        )}
      </Toolbar>
    </AppBar>

    {isAuthenticated ? (
      <>
      <h2 style={{textAlign:"center"}}>Hej {first_name}! Twoi Goście przesłali Ci następujące wpisy</h2>
      <Grid container spacing={2}>
  {Object.values(groupedWpisy).map((wpis, wpisIndex) => (
    <Grid item key={wpisIndex} xs={12} sm={6} md={4}>
      <Card className={classes.card} onClick={() => handleImageClick(wpis)}>
        <CardMedia className={classes.media} image={wpis.image || ''} title="Zdjęcie wpisu" />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {wpis.text || ''}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
{wpisy.length > 0 && (
  <>
    <SaveAllImagesButton wpisy={wpisy} />
  <Button variant="contained" color="primary" onClick={handleSaveToPDF}>Save to PDF</Button>
  </>

)}
</Grid>


          <Dialog
  fullWidth
  fullScreen
  maxWidth="md"
  open={isImageDialogOpen}
  onClose={handleImageDialogClose}
  PaperProps={{ style: { overflow: 'hidden' } }}
>
  <DialogTitle>Zdjęcia</DialogTitle>
  <DialogContent className={classes.dialogContent}>
    {selectedWpis && selectedWpis.zdjecia && (
      <Carousel
        autoPlay={false} // Wyłącz automatyczne przełączanie
        animation="slide" // Animacja przesuwania
        indicators={false} // Ukryj wskaźniki
        navButtonsAlwaysVisible // Pokaż przyciski nawigacji zawsze
        index={selectedImageIndex}
        onChangeIndex={(index) => setSelectedImageIndex(index)}
      >
        {selectedWpis.zdjecia.map((zdjecie, index) => (
          <img
            key={index}
            src={zdjecie.image}
            alt={`Zdjęcie ${index + 1}`}
            style={{ width: '100%', maxHeight: '100vh', objectFit: 'contain', marginBottom: '10px' }}
          />
        ))}
      </Carousel>
    )}
  </DialogContent>

  <DialogActions style={{ marginBottom: '50px' }}>

  <Button onClick={handleSaveImage} color="primary">
    Zapisz zdjęcie
  </Button>
  <Button onClick={handleSaveAllImages} color="primary">
    Zapisz wszystkie zdjęcia z tego wpisu
  </Button>
  <Button style={{color:"red"}} onClick={handleImageDialogClose} color="primary">
    Zamknij
  </Button>
</DialogActions>
</Dialog>

          <SwipeableDrawer anchor="right" open={isDrawerOpen} onClose={handleWpisDrawerClose} onOpen={handleWpisDrawerOpen}>
            <div className={classes.drawer}>
              <Typography variant="h6">Wpis</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={selectedWpis?.wpis} />
                </ListItem>
              </List>
            </div>
          </SwipeableDrawer>
        </>
      ) : (
        <>
          <h1>Zaloguj się, aby przejść do księgi gości!</h1>
          <Navigate to="/" />
        </>
      )}
    </div>
  );
};

export default ProfilePage;